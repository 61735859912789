import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import { analytics } from "../../services/analytics";

export const usePageView = () => {
    const location = useLocation();
    useEffect( () => {
        analytics.page(location.pathname);
    }, [location]);
}
