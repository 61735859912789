import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EyeIcon, PlusIcon } from "@heroicons/react/solid";
import routing from "../../../routing";
import Header from "../components/header";
import BillingNotification from "../../../ui/components/billing-notification";
import { useDataStreamListing } from "../../../hooks/data-stream";
import Async from "../../../ui/helper/async";
import NetworkProvider from "../../../config/network/network-provider";
import DataStreamModel from "../../../services/models/http/data-connector/data-stream/data-stream";
import Button from "../../../ui/components/button/button";
import DestinationProvider from "../../../config/destination/destination-provider";
import { analytics } from "../../../services/analytics";

type RowProps = {
  dataStream: DataStreamModel
}
const DataStreamRow = ({dataStream} : RowProps ) => <tr key={dataStream.getId()}>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="text-sm font-medium text-gray-900">
          {dataStream.getName()}
        </div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 " src={(NetworkProvider.getByPlatform(dataStream.getPlatform()).networkImage())} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{NetworkProvider.getByPlatform(dataStream.getPlatform()).getName()}</div>
        </div>
      </div>
    </td>
  <td className="px-6 py-4 whitespace-nowrap">
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 " src={(DestinationProvider.getByPlatform(dataStream.getDestination().type)?.destinationImage())} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{dataStream.getDestination().name}</div>
      </div>
    </div>
  </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{dataStream.getJobs().length}</div>
    </td>
    <td className="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
      <Link to={routing.user.data_stream_id.replace(":id", dataStream.getId().toString())}>
        <Button
          type="primary"
        >
          <EyeIcon className="h-6 w-6 mr-2" />
          View
        </Button>
      </Link>
      <Link
        to={routing.user.data_stream_job_new.replace(":id", dataStream.getId().toString())}
      >
        <Button
          type="grey"
        >
          <PlusIcon className="h-6 w-6 mr-2" />
          Add job
        </Button>
      </Link>
    </td>
  </tr>
const DataStream = () => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const AddDataStreamButton = <Link
    to={routing.user.data_stream_new}
    onClick={() => {
      analytics.track("Trying to add a data stream");
    }
    }
  ><button
    type="button"
    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    <PlusIcon className="h-6 w-6 mr-2" />
    {t('Add data stream')}
  </button></Link>

  const dataStreams = useDataStreamListing(page);
  return (
    <div className="w-full py-4">
      <BillingNotification />
      <Header title={t('Data Stream')} actionButton={AddDataStreamButton} />
      <div className="mt-8 block">
        <div className="align-middle inline-block min-w-full">
          <Async {...dataStreams}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr className="border-t border-gray-200 bg-white">
                  <th
                    scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">{t('Name')}</span>
                  </th>
                  <th
                    scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Platform')}
                  </th>
                  <th
                    scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Destination')}
                  </th>
                  <th
                    scope="col" className="text-left able-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Jobs')}
                  </th>
                  <th
                    scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dataStreams.data?.data.map((dataStream) => {
                    console.log(dataStream);
                    return <DataStreamRow dataStream={dataStream} />
                  })}

                </tbody>
              </table>
            </div>
          </Async>
        </div>
      </div>
    </div>
  );
};

export default DataStream;
