import React, { useEffect } from "react";
import qs from 'query-string';
import {
  useGetAllNetworkAuthorizationInformation,
  useGetAllNetworkAuthorizationSummary
} from "../../hooks/network-authorization";
import Loader from "../../ui/components/loader/loader";
import { useMe } from "../../hooks/user";
import { analytics } from "../../services/analytics";
const scriptIdList = {
  '1KkQ7Or2mHzUIrgGtDXoO0bWJRUoThEiMe4oCiBRJiCMcKsPcgbvylOnQ' : "bing-ads",
  '1zBUbmhmFrkZ6AttiH23gNMhnU8B3v-tPEWKHfTx4XDQM5wgRWcp02kZT' : "facebook-ads",
  '1dHDNWrWoCQ8fbz4xBjVdxnSBIcN6nu-qw-SlU-FzfwiiNiwYZZlNp27e' : "google-ads",
  '1QCJ6W121RO7-3qO6gtzbAqJafx-irjaESKPUMaQfjbsayYzWotY-WLdi' : "google-merchant-center",
  '1S_0OnNdFois-PuuSPLBo81p_luHwXniGc59eFQb_oOvu_khzIN0qdzX7' : "tik-tok-analytics",
  '1YQLWobwDOuNM4V2eQBCZ8ET30esqszGK9WmUqkw2xxcNrbq70nRTj9fX' : "facebook-analytics",
  '1btU2HiqVjDyV2U0eNtzQonyuAhrj_l7WI-wb3ciFQJC46r4LFhp5GJ5q' : "linkedin",
  '1KiEm78GGoA9J2NHcxr07w6jkEoV0Po1OeGwnGiVCCc1JGI5wW4sSj5Go' : "twitter-ads",
  '1X-VrEiSYMWIQU9zpuu9rfKAPb430tPNLcqhPMxbsn8Y25m3jnkp5LXzp' : "pinterest-ads",
  '1fdxsRvlvhPca2FMZOJ3Db0ow1mK-LQxX-1b_ZdsMDq5TOphRmxRTbNJD' : "tik-tok",
  '18BJCDpYeE_lB4C0kK1s-PENtRilSptersAQ6k4p3ICdfnjFbOFu0u7Xn' : "instagram",
  '1crLVlQ6VdRD897YyshP7fjkHz-xchqN6sARiq5ATxm0qSiUY-M87JCeL' : "shopify",
  '1OI1CXa0hyVOAcTNrKE_qAcvRAHLyKAV7p_nC_sf8r3nJ1P32zSXoL27o' : "linked-in-page",
  '1IBZqG3CiXizX59kurOWnTFcdfrzilwuG0nv2TPYLzSBWYzV17gJdRWTg' : "google-my-business",
  '15C_0DSp2l8zp_Z8HZdhmWF6LO_1kU-lT2HL4ksWeur6g1k_WIS9VtfGh' : "snapchat-ads",
  '1fs2BSBobv_RN6O7AGMBINVUNsI6X0P1v5wPboI-c-TUTFT-8slQoTohe' : 'amazon-ads',
  '1R3IzIUi0iaedIeaOUaoYby6tK0B_nSum9oeuAYs1ljOPYtTC_uArRxJ4' : 'matomo',
  '1uHOamQ_XMTZSclBxqjDMtmWCfb8Zm79EDjOdKRXv-ua4aJbjivrFEHIp' : 'woocommerce',
  '1D4glEUcLMoCcfRO86dcASrJlrwbNnYNE6IamrYnHSKFY8pVp212inoPn' : 'klaviyo',
  '1BBlnRN0WTmdMy3cUUEF8J6fx7X-EX_wekZAwnPT45sK1cK6_44VQD1eZ' : 'mailchimp',
  '1zNuLAatCm0hMh0djJxrAjy_ZiVYX_9spnu1mJ16QATxXpV6Mun-U3K0C' : 'google-analytics',
  '1ZTUlDY1VWpKTgQsmIMK9SNHM3SVF43d87LYKTaEOIraqiRlrlhOudRHk' : 'spotify-ads',
  '1SXSqjYa3fKtgGySLAp4l3yojzysb_ES7kUpyZdM9JtrMt9yuOedvRp_y' : 'pipedrive',
  '1lU36U85LsfHCkDfk8HJa2geIy1lnYLPEKLhDBRB1cJDPSz4jMzh1guGb' : 'active-campaign',
  '19KDccurARV46pm9unxegd8UyWZdvruW3tzLMofWHfD12ZcgY2YlKuG7B' : 'facebook-public-data',
  '1zDqvan-2oagp33Eo2zikqWbMwOVWY6gFhQjKSExQaHq1JYE4kQG0w90M' : 'app-store-connect',
  '1IA1HwByiCrH1HZUNhmzDn2_p5mrDL0BisgJxSCmr_fvZ9j9tNz--ZKJS' : 'stripe',
  '14IjMAKnb1K9QdFPKM6ac7aZ5GLhketVnWJDGzqbkmVO1jqUfCtmQBFes' : 'hubspot',
  '1zxWzfPxrfwdI_hauS13VAtrXIPMqgDEeB5fjms36s2pX2PNOa1pUk3u5' : 'google-play-store',
  '1EKES5hp2qjD8mPb0XxjEJ7y8kYzE0EuxiJnhKknarHIXhcYdPSTWqmrl' : 'brevo',
  '1cJAXN_JQTCALvVHUj1i_-AZY7Q4Z7whcRfmoVGpWXun9KD3jvr_RED0s' : 'apple-search-ads',
  '1H2fg_ACwVWITKTWwczScdqBvBHl_vbnUS5v8OmEQqAJ0wYZFO149qy4n' : 'youtube',
  '1VOhXkw_zpJTqj2srRr2f5JMsWnAfw8v_n2cDDWsWlJrAOezvYsjhNAVZ' : 'reddit-ads',
  '1yAp4L-InF9EzICXUVJQ0fm_LPO07D1lwxx86yGB6SczrfRakTMejrGYe' : 'ad-form',
  '1Uq-tUEQOYcElMeVu6-bhUxp5yt8b2bC-506i7ix13se2ErFZqOzFMEqx' : 'amazon-seller',
  '1OawgTGpLrXDXxP2ipskbZfiXJtZKzcgo9zyTrtkPl88Gh5lm47GCe0OO' : 'piwik',
  '1RL5KFZ5bNgSWIQOQdKEVzhbDdD8HfnAOA47k320Ir3eS1hBZG61LTO75' : 'adtraction',
  '1F16uHUOxAINI6qgK12BePpDrsunwltvRw64zOn2xwuiMr15HBcaukl16' : 'call-rail',
  '1Estf73PFPfyR13eLo9g7LjXkI47vqqKC1qrH48cOh0a7_i4yQQu-rJy4' : 'twitter-public-data',
  '1YO0ZK4fXAi52s9NPXwrIp12b9sYH00VsXeZtl_WeOUosvYZZSwptrGJG' : 'pinterest-organic',
  '1eadZtQiEYJhdCM3H3oHvCCwTsz51xgumsZgIhcADVQHYjqGAB6kSFYkC' : 'instagram-public-data',
  '1eAPNhZDXoNZ23PMD3P14HE38dtwVJxWM7FQHy5IHhbvt1qR99cRSYjM4' : 'google-search-console',
  '1yXYShvOVf_juwn2JQ01VL9jjHBPii313_JUSiWE5NTMAPhfbshdebQWr' : 'bing-webmaster-tool',
  '1wtmukkbgAs6CIgY2UuY34wuhdEk1R8Mcg9_3HOxFqJkx2lDYTjP4iAaX' : 'pardot',
  '1l1dU2Rv0x-7urkhXXGKULZaVx0G3l3QVT7Qbzh0Q2tStxyQkEyZlYq8i' : 'app-flyers',
  '15B3snf3-FpWjAlRFwTPr2i-eRKG_Xf1uUZezY2-eRGlmNgdGRBnxQo0i' : 'ad-roll',
  '1L1AViyCzqo0jrN_MV0-HvP0qIDcaumvtUtLwhKL5nPKp-8OXHKuI7TGP' : 'adjust',
  '1NCmODDkHKCX23FTTj9QLf9_7tN7mP3H7d_lFwe7jPTfY3q6RjvJE8Bdk' : 'criteo',
  '1Vl2xZMTv000TFoxj_nfeDTCLviXCIo9kP2YCEVLGwkFOnxTdZWTivPNp' : 'piano-analytics',
  '1FcMg9ptos6njdQ9VlyUZkJwYoYP9eGFxavJs45XDiZzsWQNPnNxye07a' : 'awin',
  '1deJjGtnuMDZNv2CIBEHM6mmFaMJRLCeCO20GH_S1P71jFm8oMk-UR9lt' : 'big-commerce',
  '1ulxPSmyo5QmdlumIYhjm3TqfKJQlDm-p2IcZDbpBzbpaaurxjfBKqHGU' : 'prestashop',
  '1ae10Q2HV1ll2_vj7EVvE2SY7hoBwJ8EeYXWa4t-9816FCYOb72icIARr' : 'magento',
  '1t3JAdc-65OOUDFNgWii-YFnsIvyPAeOJERWQVJRE33cgz3j8EsqNl683' : 'taboola',
  '1iwgDriZ43m96JDz4mXvRI0YuVAKDjd64jCeRd60IN673sSgwtWIUn-_u' : 'teads',
  '1Q_1MXEM1WUeKs_2zmVG19_9QILwdrBvrYIC6ua2BMINsUCbbPigfTPCP' : 'outbrains',
  '1kLLJyMvJDsmDNN9lcg1gTUXG8Y9VgWoJ3S0m5DtAh1TS3BlTn8VpXegG' : 'line-ads',
  '1nF2csiDCJ1-AoxfIn_QK2QlwabT8EqI2qAzRWqdDgF_OOmLpG8DHW8JI' : 'yahoo-japan-display',
  '1f_DJfOZHZSqi4WVvGoVlL5hmQovZUlDLkNQf3_DdmZUe7hntFY1O4iv4' : 'yahoo-japan-search-ads',
  '1C8F8VKY-08zH2RytzRR4RCPdoXkyNVbwAfUHX48qtnt9KKp6_6QMTpPh' : 'xandr',
  '1srDg1oM9H27VJc8WLaRpWYJ6Ookavlzv9RfmM1_qMmHtq9HRElGzRrQo' : 'google-page-speed',
  '1RpL-Jka2OiyMbWH9liQ90jr_uVMUKsyj2ui8xZder3npQwlgzina8kN0' : 'google-trends',
  '1FhTW38kGb4yvgMaDhlvRbH88kRnTxSoHw5fFQp6Xe22-XKPkar6h3b9k' : 'branch',
  '1q5dE0BKx954WBXnKgqXPNi1ti1uXtW5JRKqxJ2x0on88E8wjtixPLcM3' : 'airbnb-review',
  '1MEeeuD6sQr57wKGb2WTzToiI9Cqp_BJINf1S5i1pWZfyAu6jcAGvH3Vu' : 'g2-review',
  '1MWvaXLmzN8znai9MrW7kM2y0lhtwcn1Wv0r09LmvcPyqi3kHUid0Wgd1' : 'tripadvisor-review',
  '1xuz6jCsY8RynHWpHPr6hljlNDqFkx3ZQCPvcZgBuekFEhh_Jpk8PsHQh' : 'yelp-review',
  '1NaWjifhA41ew4jQwPbCjxbOg98uscNWXzAP3LRFQUwroRdrLNzzXvrqn' : 'indeed-review',
  '1s-OsUc_kNlhtinKPbclE-OLmn9w8rZ9NSCcqT51_owPTfWSTUp3pWDHD' : 'glassdoor-review',
  '1YtvqSUNOKfcjPILn_oSgbAM6foCjooZv11-B7SwQudVwKidnd5z69c0p' : 'booking-review',
  '1ofRnmHU2TDR4-BV4YMaf-JN-0EvAHm5zlTdnwE7khBGztsewWBEFrNP5' : 'google-play-review',
  '1H2gGdQyS_YWYqjCmQTTolYF3TdlVUlI5g9wfibe31j221d5HZVVzYJa2' : 'appstore-review',
  '1rlthYOtod0-iiT67OIBG-IKOxeaGPHbs5nPn38G9v5pVGwjUlPA-dN2B' : 'capterra-review',
  '1Tbp2jLi-5JXnsihCsjtcX0kV2vMtpKcFBLt0YDLwSZT_9kGunMLfps7p' : 'trustpilot-review',
  '1zann79IxpbEbMMAl5no5S49HKKKyfhsxP0sy8oqwfFR4JtU_xwF9Dyfq' : 'facebook-review',
  '1nn8cKxeiS3W7O8ZcZoSE1h-h9EyRpjucTv-EFHmp4AOBLwvMyX5Dy02V' : 'chartmogul',
  '1qwjYqJUu3QrkqopLk8sq2c8mXpBWrGqdJW6YUn078KAU3EGxujkjXAN9' : 'zoho-crm',
  '11yRAh4DC4Wt4qeiOEbQ69ZxGKQ0B06sjHvTrz-GFaFN-3TQaFhgS5uVw' : 'profitwell',
};

export const generatePath = (location: Location, path: string) => {
  const isHttps = location.protocol === 'https:';
  return `${location.protocol}//${location.hostname }:${location.port || (isHttps ? '443' : '80')}${/^\//.test(path) ? path : `/${path}`}`;
}

const Index = () => {
  const networkInformation = useGetAllNetworkAuthorizationInformation();
  const params = qs.parse(window.location.search);
  const me = useMe();
  const authorizationSummary = useGetAllNetworkAuthorizationSummary();
  let askFor = params.askFor;
  let noSkip = params.noSkip || false;

  useEffect(() => {
    if(params.email){
      analytics.identify(null, {
        email: params.email as string,
        tags: [
          'View Looker Studio Connector',
        ]
      });

      if(askFor){
        const data : any = {'source': "Google Data Studio", 'acValue' : askFor, 'platform': askFor, ...params};
        analytics.track('GOOGLE_DATA_STUDIO_VIEW_AUTHORIZE', data);
      }
    }
  }, []);
  if(!askFor){
    askFor = scriptIdList[params.scriptId as never]
  }

  if(!askFor){
    return <div>You have requested a non existent endpoint.</div>
  }

  if(networkInformation.isLoading || me.isLoading || authorizationSummary.isLoading){
    return <div className="w-full h-full flex items-center justify-center">
      <Loader active/>
    </div> ;
  }

  const find = networkInformation.data?.data.find(elem => elem.getType() === askFor);
  if(find){

    if(params.scriptId) {
      localStorage.setItem("gds-authorization-client-id", params.client_id as string);
      localStorage.setItem("gds-authorization-client-secret", params.client_secret as string);
      localStorage.setItem("gds-authorization-redirect-uri", params.redirect_uri as string);
      localStorage.setItem("gds-authorization-state", params.state as string);
      localStorage.setItem("gds-authorization-script-id", params.scriptId as string);
      localStorage.setItem("gds-authorization-email", params.email as string);
    }
    if(me.data?.data.email){
      if(authorizationSummary.data?.data.filter(elem => elem.getPlatform() ===  askFor).length && !noSkip) {
        window.location.href = "/oauth2/authorize/:platform/response?connected=true".replace(':platform', askFor as string);
        return <div>
          You will be redirected.
        </div>
      }
    }

    const url = new URL(find.getInternalEndpoint() ? generatePath(window.location, find.getInternalEndpoint() as string) : find.getEndpoint() as string);

    if(find.getInternalEndpoint()){
      url.searchParams.append("from", "google-data-studio")
    }
    window.location.replace(url);
  }

  return <div>
    You will be redirected.
  </div>
};

export default Index;
