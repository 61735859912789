import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import qs from "query-string";
import CurrentUserAPI from '../../services/repository/user/current-user'
import DestinationProvider from "../../config/destination/destination-provider";
import Loader from "../../ui/components/loader/loader";

const LinkTo = () => {
  const { platform } = useParams();
  const params = qs.parse(window.location.search);
  const [saving, setSaving] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setSaving(true);
        await CurrentUserAPI.getOneClickAuth(params.uuid as string);
      }catch (e) {
        console.error(e);
      }finally {
        setSaving(false);
      }
    })();
  },[])
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-100 flex flex-col items-center justify-center">
      {saving ? <><Loader active={saving} /><h1 className="mt-2 ml-2 pt-8 font-semibold text-xl">Trying to link Catchr
          to {DestinationProvider.getByPlatform(platform as string)?.getName()}.</h1></> :
        <>
          <img className="w-16 h-16" src={DestinationProvider.getByPlatform(platform as string)?.destinationImage()} />
          <h1 className="mt-2 font-semibold text-xl">You have successfully link Catchr
            to {DestinationProvider.getByPlatform(platform as string)?.getName()}.</h1>
          <p className="mt-2 font-light">you can return to Excel to start creating your query</p>
        </>
      }
    </div>
  );
};

export default LinkTo;
