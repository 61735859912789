import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import "./i18n";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from "@sentry/react";
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import Gleap from 'gleap';
import reportWebVitals from "./reportWebVitals";
import { store, history } from "./stores/store";
import Root from "./features/root";
import { listMap, NotificationContainer } from "./ui/components/notification/notification";
import {ConfirmContainer} from "./ui/components/confirm/confirm";
import config from "./config";

Gleap.initialize("uWX6KzjibmF1MuhSdRZ7KZMlW5npJ0qd");

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})
export const SentryRoutes = Routes;
const FallbackComponent = () => <div>An error has occurred</div>
const myFallback = <FallbackComponent />;

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
          <Router history={history}>
            <Root />
            <NotificationContainer list={listMap} />
            <ConfirmContainer />
          </Router>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
