import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import {
  useGetAllNetworkAuthorizationSummary,
  useMutateDeleteAuthorization
} from "../../../hooks/network-authorization";
import routing from "../../../routing";
import Header from "../components/header";
import Async from "../../../ui/helper/async";
import NetworkProvider from '../../../config/network/network-provider';
import NetworkAuthorization from "../../../services/models/http/network-authorization/network-authorization";
import BaseNetwork from "../../../config/network/base-network";
import Button from "../../../ui/components/button/button";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import { NotificationManager } from "../../../ui/components/notification/notification";
import MeetingBooker from "../../../ui/components/meeting-booking";
import BillingNotification from "../../../ui/components/billing-notification";
import { analytics } from "../../../services/analytics";

const RowSources = ({network, onDelete}: {network: NetworkAuthorization, onDelete: () => void}) => {
  const source = NetworkProvider.getByPlatform(network.getPlatform()) as BaseNetwork;
  const {t} = useTranslation();

  const [deleting, setDeleting] = useState(false);

  const deleteMutation = useMutateDeleteAuthorization(network.getId());

  const deleteAuthorization = async () => {
    analytics.track('Trying to delete source', {source_id: network.getId()});
    ConfirmManager.confirm({
      title: t("Confirm the deletion of the source"),
      description: t("Are you sure you want to source this destination ?"),
      validButton: t("Delete"),
      cancelButton: t("Cancel"),
      typeImg: "danger",
      onValid: async () => {
        setDeleting(true);
        try {
          analytics.track('Validate delete source', {source_id: network.getId()});
          await deleteMutation.mutateAsync();
          NotificationManager.success(t('The source has been deleted'), "");
          onDelete();
        } catch(e) {
          NotificationManager.error(t('Unable to delete the source'), t('An error occurred. Please try again.'))
        }
        setDeleting(false);
      }
    });
  }

  return <tr key={network.getId()}>

    <td className="px-6 py-4 whitespace-nowrap">
      {network.getName()}
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 " src={(source.networkImage())} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{source.getName()}</div>
          <div className="text-sm text-gray-500">{t('{{accounts}} accounts available.', {accounts: network.getTotalAvailableDataSource()})}</div>
        </div>
      </div>
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      <span className="font-semibold">
            {network.getTotalActivatedDataSource()}
        </span>
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      {network.getState() === "SUCCESS" && network.getNumberError() < 10 ? (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {t('Success sync')}
        </span>
      ) : network.getState() === "ERROR" ? (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          {t('An error occurred')}
        </span>
      ) : network.getState() === "ACCOUNT_NOT_AVAILABLE" ? (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          {t('There is an error fetching your account. Please refresh the connection.')}
        </span>
      ) : network.getNumberError() > 10 ? (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          {t('There is an error fetching your account. Please refresh the connection.')}
        </span>
      ) : null}
    </td>

    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-4">
      <Link onClick={() => {
        analytics.track('Trying to edit a source', {source_id: network.getId()})
        }
      } to={routing.user.source_edit.replace(":id", network.getId().toString())}>
        <Button type="primary-purple">
          <PencilIcon className="w-4 h-4 mr-1" />
          {t('Edit')}
        </Button>
      </Link>
      <Button type="danger" loading={deleting} onClick={deleteAuthorization}>
        <TrashIcon className="w-4 h-4 mr-1" />
        {t('Delete')}
      </Button>
    </td>
  </tr>
}

const SourceIndex = () => {

  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const sources = useGetAllNetworkAuthorizationSummary();
  const AddSourceButton = <Link onClick={() => {
    analytics.track('Trying to add source')
  }
  } to={routing.user.new_source} ><button
    type="button"
    className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    <PlusIcon className="h-6 w-6 mr-2" />
    {t('Add source')}
  </button></Link>

  return (
    <div className="w-full py-4">
      <BillingNotification />
      <Header title={t('Sources')} actionButton={AddSourceButton} />
      <div className="mt-8 block">
        <div className="align-middle inline-block min-w-full">
          <Async {...sources}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="border-t border-gray-200 bg-white">
                    <th
                      scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <span className="lg:pl-2">{t('Name')}</span>
                    </th>
                    <th
                      scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('Source')}
                    </th>
                    <th
                      scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('Number accounts connected')}
                    </th>
                    <th
                      scope="col" className="text-left able-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('Status')}
                    </th>
                    <th
                      scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {sources.data?.data.filter(elem => NetworkProvider.isAvailable(elem.getPlatform())).map(elem => <RowSources onDelete={() => sources.refetch()} network={elem as never} />)}
                </tbody>
              </table>
            </div>
          </Async>
        </div>
      </div>
      <MeetingBooker title="Get help connecting your data source !" description="Do you need help connecting your data source? Creating a report or a Google Sheets using our services? Book a meeting with our expert. Or use our chat to get a quick answer !" />

    </div>
  );
};

export default SourceIndex;
