import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { CheckCircleIcon, LockClosedIcon, MailIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import * as Yup from 'yup';
import { useDeleteUser, useGetAllUser, useMe } from "../../../hooks/user";
import Async from "../../../ui/helper/async";
import CompanyModel from "../../../services/models/http/company/company";
import Button from "../../../ui/components/button/button";
import Modal, { DefaultBottom, Title } from "../../../ui/components/modal";
import Input from "../../../ui/components/input/input";
import { NotificationManager } from "../../../ui/components/notification/notification";
import { useInviteUser } from "../../../hooks/company";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import { useDeleteDestinationMutation } from "../../../hooks/destinations";

type ModalProps = {
  visible: boolean,
  onClose: () => void,
  company: CompanyModel,
  onSuccess: () => void
}

type PeopleAdd = {
  email: string
}

const Validation = Yup.object({
  email: Yup.string().email().required()
})

const ModalAdd = ({visible, onClose, company, onSuccess}: ModalProps) => {
  const useInviteUserMutation = useInviteUser();
  const {t} = useTranslation();
  const [saving, setSaving] = useState(false);
  const form = useFormik<Partial<PeopleAdd>>({
    initialValues: {
      email: undefined
    },
    validationSchema: Validation,
    onSubmit: async (values) => {
      setSaving(true);
      try{
        await useInviteUserMutation.mutateAsync({id: company.getId(), email: values.email as string})
        NotificationManager.success(`🚀 An email has been send to ${values.email}.`, 'Once the account is activated, the user can access Catchr. ');
        onSuccess();
        form.resetForm();
      }catch (e){
        if((e as unknown as any).response?.data?.unique === false){
          NotificationManager.error(`The email ${values.email} already exists on our database.`, 'If you want to swicth the user from one company to another, please ask to your account manager.');
          form.setFieldError('email', `The email ${values.email} already exists on our database.`);
        }else {
          NotificationManager.error(`An error occurred when we try to add ${values.email}`, 'Try again or contact johan@catchr.io')
        }
      }finally {
        setSaving(false);

      }
    }
  })
  return <Modal
    open={visible}
    onClose={onClose}
    bottom={<DefaultBottom
      disabledOkButton={!(form.dirty && form.isValid)}
      onClose={onClose}
      titleButtonOk={t('Next')}
      onOk={() => form.submitForm()}
      loading={saving}
      titleButtonClose={t('Close')}
    />}
  >
    <Title title={t('Add a user to your team')} />
    <Input
      id="email"
      name="email"
      type="email"
      label="Email"

      onChange={form.handleChange}
      value={form.values.email}
      touched={form.touched.email}
      error={form.errors.email}
    />
  </Modal>
}

const Users = ({company}: {company: CompanyModel}) => {
  const {t} = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const users = useGetAllUser();
  const me = useMe();
  const [modal, setModal] = useState(false);

  const deleteMutation = useDeleteUser();
  const deleteUser = async (userId: number) => ConfirmManager.confirm({
    title: t("Confirm the deletion of the user"),
    description: t("Are you sure you want to delete this user ?"),
    validButton: t("Delete"),
    cancelButton: t("Cancel"),
    typeImg: "danger",
    onValid: async () => {

      setDeleting(true);
      try {
        await deleteMutation.mutateAsync(userId);
        await users.refetch();
        NotificationManager.success(t('The user has been deleted'), "");
      } catch(e) {
        NotificationManager.error(t('Unable to delete the user'), t('An error occurred. Please try again.'))
      }
      setDeleting(false);
    }
  });

  return (
    <>
      <ModalAdd onSuccess={() => {users.refetch(); setModal(false)}} company={company} visible={modal} onClose={() => setModal(false)} />
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="flex items-center w-100 justify-between">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Users listings')}</h2>
            <p className="mt-1 text-sm text-gray-500">
              {t('Users listing of your company.')}
            </p>
          </div>
          <div>
            <Button
              type="primary-purple"
              onClick={() => setModal(true)}
            >
              <PlusIcon className="h-5 text-white mr-2"/>
              {t('Add')}
            </Button>
          </div>
        </div>
        <Async {...users}>
          {users.data ?
            <div className="mt-6">
              <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                  {users.data.data.map(user =>
                    <li key={user.getId()}>
                      <div className="block">
                        <div className="flex items-center px-4 py-4 sm:px-6">
                          <div className="min-w-0 flex-1 flex items-center">
                            <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                              <div>
                                <p className="text-sm font-medium text-indigo-600 truncate">{user.getName()}</p>
                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                  <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  <span className="truncate">{user.getEmail()}</span>
                                </p>
                              </div>
                              <div className="hidden md:block">
                                <div>
                                  <p className="text-sm text-gray-900">
                                    {t('Created at {{time}}', {time: new Date(user.getCreatedAt()).toLocaleString()})}
                                  </p>
                                  <p className="mt-2 flex items-center text-sm text-gray-500">
                                    {/* {user.isActive() ? <><CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                                    {t('Account activated')} </> :
                                      <><LockClosedIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                                      {t('Account not activated')} </>} */}
                                  </p>
                                </div>
                              </div>
                              <div className="hidden md:block justify-self-end">
                                <div>
                                  <Button type="danger" loading={deleting} onClick={() => deleteUser(user.getId())} disabled={me.data?.getCompany().mainUserId === user.getId()}>
                                    <TrashIcon className="h-5 w-5 mr-2" />
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            : <div>No data</div>}
        </Async>
      </div>
    </div>
    </>
  );
};

export default Users;
