import React from "react";
import { Route, Routes } from "react-router-dom";
import Matomo from "./matomo";
import Shopify from "./shopify";
import Woocommerce from "./woocommerce";
import Klaviyo from "./klaviyo";
import ActiveCampaign from "./active-campaign";
import { SentryRoutes } from "../../../index";
import AppStoreConnect from "./app-store-connect";
import GooglePlayStore from "./google-play-store";
import PianoAnalytics from "./piano-analytics";
import Stripe from "./stripe";
import Brevo from "./brevo";
import AdobeAnalytics from "./adobe-analytics";
import AppleSearchAds from "./apple-search-ads";
import Adtraction from "./adtraction";
import Piwik from "./piwik";
import CallRail from "./call-rail";
import TwitterPublicData from "./twitter-public-data";
import InstagramPublicData from "./instagram-public-data";
import Adjust from "./adjust";
import BingWebmasterTool from "./bing-webmaster-tool";
import AppFlyers from "./app-flyers";
import Outbrains from "./outbrains";
import TheTradeDesk from "./the-trade-desk";
import Pardot from "./pardot";
import Awin from "./awin";
import Criteo from "./criteo";
import FacebookPublicData from "./facebook-public-data";
import AmazonSeller from "./amazon-seller";
import Magento from "./magento";
import Prestashop from "./prestashop";
import Bigcommerce from "./bigcommerce";
import Teads from "./teads";
import Taboola from "./taboola";
import LineAds from "./line-ads";
import Xandr from "./xandr";
import GooglePageSpeed from "./google-page-speed";
import GoogleTrends from "./google-trends";
import Branch from "./branch";
import AirbnbReview from "./airbnb-review";
import G2Review from "./g2-review";
import TripadvisorReview from "./tripadvisor-review";
import YelpReview from "./yelp-review";
import IndeedReview from "./indeed-review";
import GlassdoorReview from "./glassdoor-review";
import BookingReview from "./booking-review";
import GooglePlayReview from "./google-play-review";
import AppstoreReview from "./appstore-review";
import CapterraReview from "./capterra-review";
import TrustpilotReview from "./trustpilot-review";
import FacebookReview from "./facebook-review";
import Chartmogul from "./chartmogul";
import Profitwell from "./profitwell";

const AddOn = () => (
    <SentryRoutes>
      <Route path="/shopify" element={<Shopify />} />
      <Route path="/woocommerce" element={<Woocommerce />} />
      <Route path="/klaviyo" element={<Klaviyo />} />
      <Route path="/matomo" element={<Matomo />} />
      <Route path="/active-campaign" element={<ActiveCampaign />} />
      <Route path="/appstore-connect" element={<AppStoreConnect />} />
      <Route path="/google-play-store" element={<GooglePlayStore />} />
      <Route path="/piano-analytics" element={<PianoAnalytics />} />
      <Route path="/brevo" element={<Brevo />} />
      <Route path="/call-rail" element={<CallRail />} />
      <Route path="/stripe" element={<Stripe />} />
      <Route path="/adobe-analytics" element={<AdobeAnalytics />} />
      <Route path="/twitter-public-data" element={<TwitterPublicData />} />
      <Route path="/apple-search-ads" element={<AppleSearchAds />} />
      <Route path="/instagram-public-data" element={<InstagramPublicData />} />
      <Route path="/piwik" element={<Piwik />} />
      <Route path="/adjust" element={<Adjust />} />
      <Route path="/bing-webmaster-tool" element={<BingWebmasterTool />} />
      <Route path="/adtraction" element={<Adtraction />} />
      <Route path="/app-flyers" element={<AppFlyers />} />
      <Route path="/outbrains" element={<Outbrains />} />
      <Route path="/the-trade-desk" element={<TheTradeDesk />} />
      <Route path="/pardot" element={<Pardot />} />
      <Route path="/awin" element={<Awin />} />
      <Route path="/criteo" element={<Criteo />} />
      <Route path="/facebook-public-data" element={<FacebookPublicData />} />
      <Route path="/amazon-seller" element={<AmazonSeller />} />
      <Route path="/magento" element={<Magento />} />
      <Route path="/prestashop" element={<Prestashop />} />
      <Route path="/bigcommerce" element={<Bigcommerce />} />
      <Route path="/teads" element={<Teads />} />
      <Route path="/taboola" element={<Taboola />} />
      <Route path="/line-ads" element={<LineAds />} />
      <Route path="/xandr" element={<Xandr />} />
      <Route path="/google-page-speed" element={<GooglePageSpeed />} />
      <Route path="/google-trends" element={<GoogleTrends />} />
      <Route path="/branch" element={<Branch />} />
      <Route path="/airbnb-review" element={<AirbnbReview />} />
      <Route path="/g2-review" element={<G2Review />} />
      <Route path="/tripadvisor-review" element={<TripadvisorReview />} />
      <Route path="/yelp-review" element={<YelpReview />} />
      <Route path="/indeed-review" element={<IndeedReview />} />
      <Route path="/glassdoor-review" element={<GlassdoorReview />} />
      <Route path="/booking-review" element={<BookingReview />} />
      <Route path="/google-play-review" element={<GooglePlayReview />} />
      <Route path="/appstore-review" element={<AppstoreReview />} />
      <Route path="/capterra-review" element={<CapterraReview />} />
      <Route path="/trustpilot-review" element={<TrustpilotReview />} />
      <Route path="/facebook-review" element={<FacebookReview />} />
      <Route path="/chartmogul" element={<Chartmogul />} />
      <Route path="/profitwell" element={<Profitwell />} />
    </SentryRoutes>
  );

export default AddOn;
