import React from 'react';
import { analytics } from "../../../services/analytics";

type Props = {
    title: string,
    description: string
}
const MeetingBooker = ({title, description}: Props) => {
    return (
        <div className="bg-white shadow sm:rounded-lg mt-4 ">
            <div className="px-4 py-5 sm:p-6 flex align-items-center ">
                <div className="w-12 mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="block icon icon-tabler icon-tabler-calendar-time" height="100%" width="100%"
                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"/>
                        <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"/>
                        <path d="M15 3v4"/>
                        <path d="M7 3v4"/>
                        <path d="M3 11h16"/>
                        <path d="M18 16.496v1.504l1 1"/>
                    </svg>
                </div>
                <div className="flex items-center flex-row justify-between flex-grow">
                    <div className="flex sm:items-start flex-col">
                        <h3 className="text-lg tex-left font-medium leading-6 text-gray-900"> {title} </h3>
                        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                            <div className="max-w-xl text-sm text-gray-500">
                                <p>
                                    {description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                        <a
                            onClick={() => {
                                analytics.track('Book a meeting');
                            }}
                            href="https://meetings.hubspot.com/johan64"
                            type="button"
                            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        >
                            Book a meeting
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
    ;
}

export default MeetingBooker;
