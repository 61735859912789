import BaseNetwork from "./base-network";
import { LinkMethod, APIKEY } from "../../constant/link-method";
import { CHARTMOGUL, NETWORK } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { PAYMENT } from "../../constant/constant";

class Chartmogul extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Chartmogul";

  getNetworkIdentifier = (): NETWORK => CHARTMOGUL;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => PAYMENT;

  isETLActivated(): boolean {
    return true;
  }

  alertOptions = () =>  [];

}

export default Chartmogul;
