import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { XCircleIcon } from "@heroicons/react/solid";
import { networkImageFromURI } from "../../../ui/components/network";
import { APPSTORE_REVIEW } from "../../../constant/network";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import { checkConnectionStatus } from "../../../services/helper";

const validation = Yup.object({
  accounts: Yup.string().required()
})
const AppstoreReview = () => {
  const query = parse(window.location.search)
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const formik = useFormik({
    initialValues: {
      accounts: ''
    },
    validationSchema: validation,
    async onSubmit(values){
      setLoading(true);
      const returnValue = {
        key: "void",
        accounts: values.accounts
      }
      try{
        const response = await checkConnectionStatus("appstore-review", returnValue);
        if(response.getStatus()) {
          if (query.from && query.from === "google-data-studio") {
            const url = new URL(generatePath(window.location, "/oauth2/authorize/appstore-review/response"));
            url.search = (new URLSearchParams({ ...values, consumerKey: "void", success: "true" })).toString();
            window.location.replace(url);
          } else {
            window.location.search = stringify({ ...values, consumerKey: "void", success: true });
          }
        }else{
          setStatus(false);
        }
      }catch (e) {
        setStatus(false);
      }

      setLoading(false);
    }
  })
  return (
    <div className="w-full h-full">
      <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
        <h1 className="text-3xl font-bold text-white">Catchr x Appstore Review</h1>
      </div>
      {!status ? <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
            <div className="mt-2 text-sm text-red-700">
              Please review the information that you have submitted. We couldn't connect to your Appstore Review account.
            </div>
          </div>
        </div>
      </div> : null}
      <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
        <div className="flex">
          <div className="mr-4 flex-shrink-0">
            <img src={networkImageFromURI(APPSTORE_REVIEW)}
                 className="h-16 w-16 bg-white text-gray-300" />
          </div>
          <div>
            <h4 className="text-lg font-bold">{t("Connect to Appstore Review")}</h4>
            <p className="mt-1">
              {t("In order to connect to your Appstore Review account, we need to get some informations.")}
            </p>
            <p className="mt-1">
              {t("You can find the account identifier that uniquely identifies an app in the url in the App Store. For example, if the url for the an app in the App Store is https://apps.apple.com/us/app/whatsapp-messenger/id310633997 the identifier is 310633997")}
            </p>
            <p className="mt-1">
              {t("Additionally you can add multiple identifiers by separating them with commas, for example : 310633997,identifier2.")}
            </p>
          </div>
        </div>
        <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
          <Input
              label="List of Identifier ids (separate by commas):"
              id="accounts"
            name="accounts"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.accounts}
            touched={formik.touched.accounts}
            error={formik.errors.accounts}
            required
          />
          <Button loading={loading} full size="large" type="primary" htmlType="submit">
            Next
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AppstoreReview;
