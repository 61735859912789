import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import BaseNetwork from "../../../../config/network/base-network";
import NetworkProvider from "../../../../config/network/network-provider";
import { CREATE, EDIT } from "../../../../constant/crud";
import { classNames } from "../../../../services/helper";
import {
  ADS,
  ANALYTICS,
  CRM,
  E_COMMERCE,
  EMAILING, PAYMENT,
  PRODUCT_FEED, REVIEW,
  SOCIAL,
  STORE_APPLICATION
} from "../../../../constant/constant";
import {
  useGetAllNetworkAuthorizationInformation,
  useMutatePutNetworkAuthorization
} from "../../../../hooks/network-authorization";
import Async from "../../../../ui/helper/async";
import NetworkAuthorizationInformation
  from "../../../../services/models/http/network-authorization/network-authorization-information";
import Input from "../../../../ui/components/input/input";
import { LinkMethod } from "../../../../constant/link-method";
import ConnectionHandler from "../options/connection-handler";
import Button from "../../../../ui/components/button/button";
import { NotificationManager } from "../../../../ui/components/notification/notification";
import routing from "../../../../routing";
import NetworkAuthorizationModel from "../../../../services/models/http/network-authorization/network-authorization";
import { isEmpty } from "lodash";
import { analytics } from "../../../../services/analytics";

export type OAuth2 = {

}

export type NetworkAuthorization = {
  name: string,
  options: OAuth2
}

const FirstStep = ({ onSelect, authorizations }: { authorizations: Array<NetworkAuthorizationInformation>, onSelect: (network: BaseNetwork) => void }) => {
  const [currentTab, setCurrentTab] = useState("all");
  const [search, setSearch] = useState<undefined|string>(undefined);
  const tabs = [
    { id: "all", name: "All", content: NetworkProvider.getAll() },
    { id: "ads", name: "Ads", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === ADS) },
    {
      id: "product_feed",
      name: "Product Feed",
      content: NetworkProvider.getAll().filter(elem => elem.categoryType() === PRODUCT_FEED)
    },
    { id: "social", name: "Social", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === SOCIAL) },
    { id: "application-store", name: "Application Store", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === STORE_APPLICATION) },
    { id: "crm", name: "CRM", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === CRM) },
    { id: "emailing", name: "Emailing", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === EMAILING) },
    { id: "analytics", name: "Analytics", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === ANALYTICS) },
    { id: "e-commerce", name: "E-commerce", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === E_COMMERCE) },
    { id: "finance", name: "Finance", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === PAYMENT) },
    { id: "review", name: "Review", content: NetworkProvider.getAll().filter(elem => elem.categoryType() === REVIEW) }
  ];

  const currentTabContent : Array<BaseNetwork> = ((tabs.find(elem => elem.id === currentTab) as any).content as Array<BaseNetwork>).filter(elem => !search || search.length === 0 || elem.getName().toLowerCase().indexOf(search.toLowerCase()) !== -1);

  const { t } = useTranslation();
  return <div className="w-full">
    <div className="border-b border-gray-200 ">
      <div className="sm:flex sm:items-center sm:justify-between">
        <h3 className="text-2xl font-bold leading-7  text-gray-900">{t("Select the source type")}</h3>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <label htmlFor="mobile-search-candidate" className="sr-only">
            Search
          </label>
          <label htmlFor="desktop-search-candidate" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="mobile-search-candidate"
                id="mobile-search-candidate"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:hidden border-gray-300"
                placeholder={t("Search")}
                onChange={e => setSearch(e.target.value)}
                value={search}
              />
              <input
                type="text"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
                placeholder={t("Search")}
                onChange={e => setSearch(e.target.value)}
                value={search}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue={(tabs.find((tab) => tab.id === currentTab) as any).name as string}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                onClick={() => setCurrentTab(tab.id)}
                href={`#${tab.id}`}
                className={classNames(
                  tab.id === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.id === currentTab ? "page" : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
    <div>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-6 mt-4">
        {
          currentTabContent.filter(network => authorizations.find(elem => network.getNetworkIdentifier() === elem.getType())).map(elem => <li
            className="cursor-pointer hover:bg-gray-50 col-span-1 flex flex-col text-center bg-white rounded-lg border border-gray-200 divide-y divide-gray-200"
            onClick={e => {
              analytics.track("Select source", {source: elem.getName()})
              onSelect(elem)
            }}
          >
            <div className="flex-1 flex flex-col p-4">
              <img className="w-16 h16 flex-shrink-0 mx-auto" src={elem.networkImage()} alt="" />
              <h3 className="mt-6 text-gray-900 text-sm font-medium">{elem.getName()}</h3>
            </div>
          </li>)
        }
      </ul>
    </div>
  </div>;
};

const optionProvider = (type: LinkMethod) => {
  switch (type) {
    case "oauth2":
      return ConnectionHandler;
    case "api-key":
      return ConnectionHandler;
    case "oauth10A":
      return ConnectionHandler;
    default:
      throw new Error(`Unable to found the type provider ${type}`);
  }
}

const Form = ({ mode, network, authorization, onSubmit, initValue, submitting }: { submitting?: boolean, initValue?:NetworkAuthorizationModel, mode: CREATE | EDIT , network: BaseNetwork, authorization: NetworkAuthorizationInformation, onSubmit: (source: NetworkAuthorization) => void}) => {

  console.log(network, authorization);
  const {t} = useTranslation();

  const [success, setSuccess] = useState(false);

  const initialValues = {
    name: initValue? initValue.getName() : '',
    options: {

    }
  };

  const formik = useFormik<NetworkAuthorization>({
    initialValues,
    onSubmit: (values) => {
      analytics.track("Save source", {source_id: initValue ? initValue.getId() : null});
      onSubmit(values);
    }
  })

  return (
    <div className="space-y-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                  <img className="h-10 w-10 " src={(network.networkImage())} alt="" />
                </div>
                <div className="ml-4">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900">{mode === "CREATE" ? t("Add a new source") : t("Update your source")}</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("Once you have add your source, you can select which accounts should be add to the platform.")}
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <Input
                id="name"
                name="name"
                label={t("Name :")}
                type="text"
                error={formik.errors.name}
                value={formik.values.name}
                touched={formik.touched.name}
                onChange={formik.handleChange}
                onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                description={t("Set a name for the source.")}
              />
            </div>
          </div>
          {
            formik.values.name.length > 0 && !formik.errors.name?
              <div className="py-6 px-4 space-y-6 sm:p-6">
                {React.createElement(optionProvider(authorization.getAuthType() as never as LinkMethod), {name: formik.values.name, authorization, onSuccess: setSuccess, initValue})}
              </div> :
            null}
          {success || !isEmpty(initValue) ? <div className="py-6 px-4 flex justify-end">
            <Button loading={submitting || false} type="primary" htmlType="submit">
              {t('Save')}
            </Button>
          </div> : null}
        </div>
      </form>
    </div>
  )

}

const SourceForm = ({ mode, networkAuthorization}: { mode: CREATE | EDIT, networkAuthorization?: NetworkAuthorizationModel }) => {
  const [platform, setPlatform] = useState<BaseNetwork | null>(networkAuthorization ? NetworkProvider.getByPlatform(networkAuthorization.getPlatform()) as BaseNetwork: null);
  const informations = useGetAllNetworkAuthorizationInformation();
  console.log("Platform", platform);
  console.log("NetworkAuthorization", informations);
  const {t} = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const putMutation = useMutatePutNetworkAuthorization();
  const onSubmit = async (data: NetworkAuthorization) => {
    setSubmitting(true);
    try {
      if(mode === "CREATE") {
        NotificationManager.success(t('Your Source has been added.'), t('You can now use this source in connection tab.'));
      }else{
        const response = await putMutation.mutateAsync({
          id: (networkAuthorization?.getId() as number),
          data: {
            name: data.name
          }
        });
        NotificationManager.success(t('Your Source has been updated.'), t('You can now use this source in connection tab.'));

      }
      navigate(routing.user.source);
    }catch (e){
      NotificationManager.error(t('Unable to create the source.'), t('An error occurred when we try to add your source.'));
    }finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full">
      <Async {...informations}>
        {platform ? <div className="py-4 flex justify-center"><Form submitting={submitting} initValue={networkAuthorization} onSubmit={onSubmit} network={platform} mode={mode} authorization={informations.data?.data.find(elem => elem.getType() === platform?.getNetworkIdentifier()) as NetworkAuthorizationInformation}/></div>  : <FirstStep authorizations={informations.data?.data as Array<NetworkAuthorizationInformation>} onSelect={(network) => setPlatform(network)} />}
      </Async>
    </div>
  );
};

export default SourceForm;
