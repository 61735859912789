import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, ADWORDS, ZOHO_CRM } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS, CRM } from "../../constant/constant";
import { CONVERSION_TAG, SECURITY, URL_CHECKER, ADS as ADS_TYPE } from "../../constant/alert";

class GoogleAds extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Zoho CRM";

  getNetworkIdentifier = (): NETWORK => ZOHO_CRM;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => CRM;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [CONVERSION_TAG,  SECURITY, URL_CHECKER];

  connectionMethod(args: any) : any {
    return {
      "location" : args.location,
    };
  }
}

export default GoogleAds;
