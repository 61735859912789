import BaseNetwork from "./base-network";
import {APIKEY, LinkMethod} from "../../constant/link-method";
import { GOOGLE_PAGE_SPEED, NETWORK, PRESTASHOP } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ANALYTICS, E_COMMERCE } from "../../constant/constant";

class Pagespeed extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Google Page Speed";

  getNetworkIdentifier = (): NETWORK => GOOGLE_PAGE_SPEED;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ANALYTICS;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];


  connectionMethod(args: any): any {
    return {
      "urls": args.urls,
    }
  }
}

export default Pagespeed;
