import BaseNetwork from "./base-network";
import { LinkMethod, APIKEY } from "../../constant/link-method";
import {CAPTERRA_REVIEW, NETWORK} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { REVIEW } from "../../constant/constant";

class CapterraReview extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Capterra Review";

  getNetworkIdentifier = (): NETWORK => CAPTERRA_REVIEW;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => REVIEW;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];

  connectionMethod(args: any): any {
    return {
      "accounts": args.accounts,
    }
  }
}

export default CapterraReview;