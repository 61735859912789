import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import qs from "query-string";
import decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import TextTransition, { presets } from "react-text-transition";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import AuthorizeAPI from "../../../services/repository/network-authorization/authorize";
import LoginAPI from "../../../services/repository/user/login";
import UserAPI from "../../../services/repository/user/current-user";
import JWT from "../../../services/auth/jwt";
import NetworkProvider from "../../../config/network/network-provider";
import BaseNetwork from "../../../config/network/base-network";
import Button from "../../../ui/components/button/button";
import config from "../../../config";
import { analytics } from "../../../services/analytics";

const OAuth2 = () => {

  const params = useParams();
  const { platform } = params;

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const GDSParams = {
    clientId: localStorage.getItem("gds-authorization-client-id") as string,
    clientSecret: localStorage.getItem("gds-authorization-client-secret") as string,
    redirectUri: localStorage.getItem("gds-authorization-redirect-uri") as string,
    state: localStorage.getItem("gds-authorization-state") as string,
    scriptId: localStorage.getItem("gds-authorization-script-id") as string,
    email: localStorage.getItem("gds-authorization-email") as string
  };

  const redirect = async () => {
    setLoading(true);
    const response = await LoginAPI.getCode(GDSParams.clientId, GDSParams.clientSecret, GDSParams.redirectUri);
    const url = new URL(`https://script.google.com/macros/d/${GDSParams.scriptId}/usercallback`);
    url.searchParams.append("code", response.data.code);
    url.searchParams.append("state", GDSParams.state);
    await analytics.track("GOOGLE_DATA_STUDIO_CONNECTED", {
      "source": "Google Data Studio",
      "platform": platform,
      "acValue" : platform
    });
    // @ts-ignore
    window.location = url;
  };


  if (!platform) {
    analytics.track("GOOGLE_DATA_STUDIO_ERROR_AUTH", {
      "source": "Google Data Studio",
      "platform": platform,
      "error": "No platform provided."
    });
    return <div>No platform provided</div>;
  }
  const query = qs.parse(window.location.search);

  if (!query.code && !query.connected && !query.success && !query.oauth_token && !query.spapi_oauth_code) {

    analytics.track("GOOGLE_DATA_STUDIO_ERROR_AUTH", {
      "source": "Google Data Studio",
      "platform": platform,
      query,
      "error": "No code provided."
    });

    analytics.track("GOOGLE_DATA_STUDIO_NO_CODE_PROVIDED", {
      "source": "Google Data Studio",
      "platform": platform,
      query,
    });

    return <>
      <div>We do not find any way to auth</div>
      {query.error ? <div>{query.error}</div> : null}
      {query.error_description ? <div>{query.error_description}</div> : null}
      {query.error_uri ? <div>{query.error_uri}</div> : null}
    </>;
  }
  useEffect(() => {
    const create = async () => {
      try {
        await analytics.track("GOOGLE_DATA_STUDIO_SUCCESS_AUTH", {
          "source": "Google Data Studio",
          "platform": platform
        });
        let data = null;
        if(query.consumerKey || query.consumerSecret) {
          data = await AuthorizeAPI._authorize(platform, {
            key: query.consumerKey as string,
            secret: query.consumerSecret as string,
            name: platform,
            email: GDSParams.email,
            options: (NetworkProvider.getByPlatform(platform) as BaseNetwork).connectionMethod(query)
          }, "register");
        }else if(query.oauth_token) {
          data = await AuthorizeAPI._authorize(platform, {
            oauthToken: query.oauth_token as string,
            oauthVerifier: query.oauth_verifier as string,
            name: platform,
            email: GDSParams.email,
            options: (NetworkProvider.getByPlatform(platform) as BaseNetwork).connectionMethod(query)
          }, "register");
        } else {
          data = await AuthorizeAPI._authorize(platform, {
            code: (query.code as string),
            name: platform,
            email: GDSParams.email,
            options: (NetworkProvider.getByPlatform(platform) as BaseNetwork).connectionMethod(query)
          }, "register");
        }
        const token = (data.data as any).jwt;
        const user = decode(token) as { id:number , email: string, company_id: number };
        JWT.setToken(token);
        const currentUser = await UserAPI._getMe()
        await analytics.alias(user.id);
        await analytics.identify(user.id as unknown as string, {
          id: user.id,
          email: user.email,
          'Free trial end at': new Date(currentUser.getCompany().trialFinished),
          'Status': currentUser.getCompany().stripeSubscriptionId ? 'Payed' : 'Free',
          'lifecyclestage': currentUser.getCompany().stripeSubscriptionId ? 'customer' : 'opportunity',
          company : {
            name: user.email,
            id: user.company_id,
          }
        });
        await analytics.track("SIGN_UP_FROM_FRONTEND", {
          email: user.email
        });
         redirect();
      } catch (e) {
        console.error(e);
        await analytics.track("GOOGLE_DATA_STUDIO_ERROR_AUTH", {
          "source": "Google Data Studio",
          "platform": platform,
          "error": "Unable to sign-in."
        });
      }
    };

    if(query.connected){
      return;
    }
    create();
  }, []);

  const steps = [
    t('We are currently looking for account in your platform. It could not take more than 15 seconds.'),
    t("Connect to the platform..."),
    t("Discover the number of account..."),
    t("Synchronizing all the metadata information...")
  ];

  React.useEffect(() => {
    const intervalId = setInterval(() =>
        setStep(step => Math.min(step + 1, 4)),
      3000 // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);


  return <div className="w-full h-full ">
    <div className="flex w-full items-center justify-center flextext-center gap-x-6 bg-yellow-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white ">
        <ExclamationCircleIcon className="w-4 inline mr-1"/>
        <strong className="font-semibold">Important information :&#xA0;</strong>
         Due to a bug in the way Google link your account to Looker Studio, if you have a 400 Bad request error in the step, please follow&#xA0;<a aria-hidden="true" className="underline" href="https://www.catchr.io/post/error-looker-studio-bad-request-error-400">this tutorial.</a>
      </p>
    </div>
      <div className="flex items-center justify-center" id="authorize-loading">
        <div className="max-w-md">
          <div>
            <div className="checkmark w-32">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0, 0, 100, 100" id="checkmark">
                <g transform="">
                  <circle className={`path ${step > 3 ? 'path-complete' : null}`} fill="none" stroke="#9333ea" strokeWidth="4" strokeMiterlimit="10" cx="50"
                          cy="50"
                          r="44" />
                  <circle className={`fill ${step > 3 ? 'fill-complete success' : null}`} fill="none" stroke={`${step > 3 ? '#9333ea' : '#22c55e'}`} strokeWidth="4" strokeMiterlimit="10" cx="50"
                          cy="50"
                          r="44" />
                  <polyline className={`check ${step > 3 ? 'check-complete success' : null}`} fill="none" stroke={`${step > 3 ? '#9333ea' : '#22c55e'}`} strokeWidth="8" strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="70,35 45,65 30,52  " />
                </g>
              </svg>
            </div>
          </div>
          <div>
            <h4 className="mt-1  text-gray-500 text-center">

              {step <= 3 ? <TextTransition
                text={ steps[step] }
                springConfig={ presets.wobbly }
                inline
              /> : <Button type="primary-purple" onClick={() => redirect()} loading={loading}>
                {t('Continue')}
              </Button>}
            </h4>
            <div className="mt-2">
              <h6 className=" text-center font-medium text-gray-900">{t('During this time, you can : ')}</h6>
              <div className="flow-root mt-6">
                <ul role="list" className="-my-5 divide-y divide-gray-200">
                    <li className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img className="h-8 w-8 rounded-full" alt="" src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Chrome_icon_%28September_2014%29.svg"/>
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">{t('Discover our Google Chrome Extension for Google Data Studio')}</p>
                          <p className="text-sm text-gray-500 truncate">{t('It help you to be more productive with your day to day operation.')}</p>
                        </div>
                        <div>
                          <a
                            href="https://chrome.google.com/webstore/detail/catchr/pjjkjaoojgmglgafnmkkckggbnabdpko"
                            target="_blank"
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50" rel="noreferrer"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </li>
                  <li className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img className="h-8 w-8 rounded-full" alt="" src="https://upload.wikimedia.org/wikipedia/commons/d/d5/Slack_icon_2019.svg"/>
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">{t('Join our Slack community')}</p>
                          <p className="text-sm text-gray-500 truncate">{t('The first community about digital marketing.')}</p>
                        </div>
                        <div>
                          <a
                            href="https://join.slack.com/t/googledatastudio/shared_invite/zt-1b4d0921e-RGk5TW5nYhCFtUNkWPFp3Q"
                            target="_blank"
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50" rel="noreferrer"
                          >
                            Join
                          </a>
                        </div>
                      </div>
                    </li>
                  <li className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img className="h-8 w-8 rounded-full" alt="" src="https://upload.wiksimedia.org/wikipedia/commons/d/d5/Facebook_F_icon.svg"/>
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">{t('Join our Facebook community')}</p>
                          <p className="text-sm text-gray-500 truncate">{t('To get help on Google Data Studio.')}</p>
                        </div>
                        <div>
                          <a
                            href="https://www.facebook.com/groups/1029468657961744"
                            target="_blank"
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50" rel="noreferrer"
                          >
                            Join
                          </a>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>;
};

export default OAuth2;
