import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PencilIcon, PlusIcon, RefreshIcon, TrashIcon, PresentationChartBarIcon, ChartBarIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import Select, { createFilter } from "react-select";
import Header from "../components/header";
import { useConnection, useDeleteConnectionMutation } from "../../../hooks/connections";
import Async from "../../../ui/helper/async";
import ConnectionModel from "../../../services/models/http/data-connector/connection";
import { networkImageFromURI } from "../../../ui/components/network";
import NetworkProvider from '../../../config/network/network-provider';
import BaseNetwork from "../../../config/network/base-network";
import DestinationProvider from "../../../config/destination/destination-provider";
import BaseDestination from "../../../config/destination/base-destination";
import routing from "../../../routing";
import { Destination, GOOGLE_DATA_STUDIO, GOOGLE_SHEETS, POWERBI, TABLEAU } from "../../../constant/destination";
import Button from "../../../ui/components/button/button";
import ConnectionAPI from "../../../services/repository/data-connector/connection";
import { ConfirmManager } from "../../../ui/components/confirm/confirm";
import { NotificationManager } from "../../../ui/components/notification/notification";
import MeetingBooker from "../../../ui/components/meeting-booking";
import BillingNotification from "../../../ui/components/billing-notification";
import { useMe } from "../../../hooks/user";
import { analytics } from "../../../services/analytics";

export const RowConnection = ({connection, onDelete} : {connection: ConnectionModel, onDelete: () => void}) => {
  const {t} = useTranslation();
  const isEditable = connection.getDestinationType() !== GOOGLE_DATA_STUDIO && connection.getDestinationType() !== GOOGLE_SHEETS && connection.getDestinationType() !== POWERBI && connection.getDestinationType() !== TABLEAU;
  const [queueing, setQueueing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteMutation = useDeleteConnectionMutation(connection.getId());

  const navigate = useNavigate();

  const deleteConnection = async () => {

    analytics.track('Trying to delete connection', {connection_id: connection.getId().toString()});
    return ConfirmManager.confirm({
      title: t("Confirm the deletion of the connection"),
      description: t("Are you sure you want to delete this connection ?"),
      validButton: t("Delete"),
      cancelButton: t("Cancel"),
      typeImg: "danger",
      onValid: async () => {
        setDeleting(true);
        try {
          await deleteMutation.mutateAsync();
          NotificationManager.success(t('The connection has been deleted'), "");
          onDelete();
        } catch(e) {
          NotificationManager.error(t('Unable to delete the connection'), t('An error occurred. Please try again.'))
        }
        setDeleting(false);
      }
    });
  }

  const source = NetworkProvider.getByPlatform(connection.getPlatform()) as BaseNetwork;
  const destination = DestinationProvider.getByPlatform(connection.getDestinationType()) as BaseDestination;
  return <tr key={connection.getId()} className="hover:bg-gray-100r">
    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
      {connection.getName()}
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 " src={networkImageFromURI(connection.getPlatform())} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{connection.getDataSourceName()}</div>
          <div className="text-sm text-gray-500">{source.getName()}</div>
        </div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">

      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 " src={(destination.destinationImage())} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{destination.getName()}</div>
          <div className="text-sm text-gray-500">{destination.getName()}</div>
        </div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{connection.getLastSeen() !== null ? new Date(connection.getLastSeen() as string).toLocaleString() : "Not run"}</td>

    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
        <Button type="primary-purple" onClick={() => {
          analytics.track('View connection', {connection_id: connection.getId().toString()});
          navigate(`/data-connector/connections/${connection.getId()}/details`)
        }}>
          <ChartBarIcon className="w-4 h-4 mr-1" />
          {t('Details')}
        </Button>
    </td>
  </tr>
}
const HomeIndex = () => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const connections = useConnection(1, {pagination: false})
  const me = useMe();

  const [selectDestination, setSelectDestination] = useState<Destination|undefined>(undefined);
  const [selectSource, setSelectSource] = useState<number|undefined>(undefined);
  const [selectPlatform, setSelectPlatform] = useState<string|undefined>(undefined);

  const RightSideButton = () => {
    const sources : Array<{type: BaseNetwork, name: string, id: number}> = [];
    let destinations: Array<{type: BaseDestination}> = [];
    let platforms: Array<{type: BaseNetwork}> = [];
    connections.data?.data.forEach((connection) => {
      const source = NetworkProvider.getByPlatform(connection.getPlatform()) as BaseNetwork;
      const destination = DestinationProvider.getByPlatform(connection.getDestinationType()) as BaseDestination;
      sources.push({type: source, name: connection.getDataSourceName(), id: connection.getDataSourceId()});
      destinations.push({type: destination});
      platforms.push({type: source});
    })

    destinations = uniqBy(destinations, (e) => e.type.getDestinationIdentifier());
    platforms = uniqBy(platforms, (e) => e.type.getNetworkIdentifier());

    const optionsDestination = destinations.map(destination => {
      const label = <span className="flex items-center"><img className="h-6 mr-1" alt={destination.type.getName()} src={destination.type.destinationImage()} />{destination.type.getName()}</span>
      return {value: destination.type.getDestinationIdentifier(), label}
    });

    const sourceDestination = sources.map(source => {
      const label = <span className="flex items-center"><img className="h-6 mr-1" alt={source.type.getName()} src={source.type.networkImage()} />{`${source.name}`}</span>
      return {value: source.id, label, name: source.name}
    });

    const platformDestination = platforms.map(platform => {
      const label = <span className="flex items-center"><img className="h-6 mr-1" alt={platform.type.getName()} src={platform.type.networkImage()} />{`${platform.type.getName()}`}</span>
      return {value: platform.type.getNetworkIdentifier(), label, name: platform.type.getName()}
    });

    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      stringify: (option : any) => {
        return `${option.data.name}`
      },
      trim: true,
    };

    return <div className="w-full flex space-x-2 justify-end">
        <Select placeholder="Select the destination" isClearable options={optionsDestination} value={optionsDestination.find(elem => elem.value === selectDestination)} onChange={e => setSelectDestination(e?.value)} classNamePrefix="react-select" className="w-1/3"/>
        <Select placeholder="Select the platform" isClearable options={platformDestination} value={platformDestination.find(elem => elem.value === selectPlatform)} onChange={e => setSelectPlatform(e?.value)} classNamePrefix="react-select" className="w-1/3"/>
        <Select filterOption={createFilter(filterConfig)} placeholder="Select the datasource" isClearable isSearchable options={sourceDestination}  value={sourceDestination.find(elem => elem.value === selectSource)} onChange={e => setSelectSource(e?.value)} classNamePrefix="react-select" className="w-1/3" />
    </div>
  }
  return (
    <div className="w-full py-4">
      <BillingNotification />
      {/* {me.data?.getCompany().networkAuthorizations.length === 0 ? <OnboardingModal /> : null} */}
      <Header title={t('Connection')} actionButton={<RightSideButton />} autoGrowth/>
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full ">
          <Async {...connections}>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr className="border-t border-gray-200 bg-white">
                  <th
                    scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">{t('Name')}</span>
                  </th>
                  <th
                    scope="col" className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="lg:pl-2">{t('Source')}</span>
                  </th>
                  <th
                    scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Destination')}
                  </th>
                  <th
                    scope="col" className="text-left table-cell px-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Last Seen At')}
                  </th>
                  <th
                    scope="col" className="pr-6 py-3 border-b border-gray-200  text-xs font-medium text-gray-500 uppercase tracking-wider" />
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {connections.data?.data.filter(elem => (selectDestination === undefined || elem.getDestinationType() === selectDestination) && (selectPlatform === undefined || elem.getPlatform() === selectPlatform) && (selectSource === undefined || elem.getDataSourceId() === selectSource)).map(elem => <RowConnection connection={elem} onDelete={() => connections.refetch()} />)}
                </tbody>
              </table>
            </div>
          </Async>
        </div>
      </div>
      <MeetingBooker title="Get help connecting your BigQuery !" description="Do you need help creating a connection? Book a meeting with our expert. Or use our chat to get a quick answer! PS: If you need to connect to Looker Studio or Google Sheets, you just need to create a new Source." />
    </div>
  );
};

export default HomeIndex;
