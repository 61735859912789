import GoogleAds from "./google-ads";
import BaseNetwork from "./base-network";
import PinterestAds from "./pinterest-ads";
import FacebookAds from "./facebook-ads";
import BingAds from "./bing-ads";
import TwitterAds from "./twitter-ads";
import GoogleMerchantCenter from "./google-merchant-center";
import FacebookPages from "./facebook-pages";
import LinkedInAds from "./linkedin";
import TikTokAds from "./tik-tok-ads";
import AmazonAds from "./amazon-ads";
import Instagram from "./instagram";
import Shopify from "./shopify";
import TikTokAnalytics from "./tik-tok";
import GoogleMyBusiness from "./google-my-business";
import LinkedinPage from "./linkedin-page";
import SnapchatAds from "./snapchat-ads";
import Woocommerce from "./woocommerce";
import SpotifyAds from "./spotify-ads";
import Matomo from "./matomo";
import Klaviyo from "./klaviyo";
import Mailchimp from "./mailchimp";
import GoogleAnalytics from "./google-analytics";
import RedditAds from "./reddit-ads";
import Pipedrive from "./pipedrive";
import ActiveCampaign from "./active-campaign";
import AdForm from "./ad-form";
import FacebookPublicData from "./facebook-public-data";
import AppStoreConnect from "./app-store-connect";
import GooglePlayStore from "./google-play-store";
import PianoAnalytics from "./piano-analytics";
import Stripe from "./stripe";
import Hubspot from "./hubspot";
import Brevo from "./brevo";
import AdobeAnalytics from "./adobe-analytics";
import Youtube from "./youtube";
import AppleSeachAds from "./apple-search-ads";
import AmazonSeller from "./amazon-seller";
import Piwik from "./piwik";
import Adtraction from "./adtraction";
import CallRail from "./call-rail";
import TwitterPublicData from "./twitter-public-data";
import PinterestOrganic from "./pinterest-organic";
import InstagramPublicData from "./instagram-public-data";
import GoogleSearchConsole from "./google-search-console";
import Adjust from "./adjust";
import AdRoll from "./ad-roll";
import BingWebmasterTools from "./bing-webmaster-tools";
import AppFlyers from "./app-flyers";
import TheTradeDesk from "./the-trade-desk";
import Outbrains from "./outbrains";
import Pardot from "./pardot";
import Awin from "./awin";
import Criteo from "./criteo";
import AmazonDSP from "./amazon-dsp";
import Magento from "./magento";
import Bigcommerce from "./bigcommerce";
import Prestashop from "./prestashop";
import Teads from "./teads";
import Taboola from "./taboola";
import LineAds from "./line-ads";
import YahooJapanDisplay from "./yahoo-japan-display";
import YahooJapanSearchAds from "./yahoo-japan-search-ads";
import Xandr from "./xandr";
import Pagespeed from "./pagespeed";
import GoogleTrends from "./google-trends";
import Branch from "./branch";
import AirbnbReview from "./airbnb-review";
import G2Review from "./g2-review";
import TripadvisorReview from "./tripadvisor-review";
import YelpReview from "./yelp-review";
import IndeedReview from "./indeed-review";
import GlassdoorReview from "./glassdoor-review";
import BookingReview from "./booking-review";
import GooglePlayReview from "./google-play-review";
import AppstoreReview from "./appstore-review";
import CapterraReview from "./capterra-review";
import TrustpilotReview from "./trustpilot-review";
import FacebookReview from "./facebook-review";
import ZohoCrm from "./zoho-crm";
import Chartmogul from "./chartmogul";
import Profitwell from "./profitwell";

class NetworkProvider {

  private available: BaseNetwork[];

  constructor() {
    this.available = [
      new GoogleAds(),
      new PinterestAds(),
      new FacebookAds(),
      new AppStoreConnect(),
      new GooglePlayStore(),
      new PianoAnalytics(),
      new TwitterPublicData(),
      new BingAds(),
      new Stripe(),
      new PinterestOrganic(),
      new InstagramPublicData(),
      new Hubspot(),
      new Brevo(),
      new TwitterAds(),
      new GoogleMerchantCenter(),
      new FacebookPublicData(),
      new CallRail(),
      new FacebookPages(),
      new LinkedInAds(),
      new TikTokAds(),
      new AmazonAds(),
      new Instagram(),
      new Shopify(),
      new TikTokAnalytics(),
      new GoogleMyBusiness(),
      new LinkedinPage(),
      new SnapchatAds(),
      new SpotifyAds(),
      new Woocommerce(),
      new Matomo(),
      new Mailchimp(),
      new Klaviyo(),
      new GoogleAnalytics(),
      new RedditAds(),
      new Pipedrive(),
      new ActiveCampaign(),
      new AdForm(),
      new AppleSeachAds(),
      new AdobeAnalytics(),
      new Youtube(),
      new AmazonSeller(),
      new Piwik(),
      new Adtraction(),
      new GoogleSearchConsole(),
      new Adjust(),
      new AdRoll(),
      new BingWebmasterTools(),
      new AppFlyers(),
      new TheTradeDesk(),
      new Outbrains(),
      new Pardot(),
      new Awin(),
      new Criteo(),
      new AmazonDSP(),
      new Magento(),
      new Bigcommerce(),
      new Prestashop(),
      new Teads(),
      new Taboola(),
      new LineAds(),
      new YahooJapanDisplay(),
      new YahooJapanSearchAds(),
      new Xandr(),
      new Pagespeed(),
      new GoogleTrends(),
      new Branch(),
      new ZohoCrm(),
      new AirbnbReview(),
      new G2Review(),
      new TripadvisorReview(),
      new YelpReview(),
      new IndeedReview(),
      new GlassdoorReview(),
      new BookingReview(),
      new GooglePlayReview(),
      new AppstoreReview(),
      new CapterraReview(),
      new TrustpilotReview(),
      new FacebookReview(),
      new Chartmogul(),
      new Profitwell()
    ].sort((a, b) => a.getName().localeCompare(b.getName()));
  }

  public getAllDataStudio(){
    return this.available.filter(e => e.isDataStudioActivate());
  }

  public getAll(){
    return this.available;
  }

  public getByPlatform(platform: string){
    return this.available.find(elem => elem.getNetworkIdentifier() === platform) as BaseNetwork
  }

  public isAvailable(platform: string){
    return this.available.some(elem => elem.getNetworkIdentifier() === platform)
  }
}

export default new NetworkProvider();
