export const ADS = "ADS";
export const PRODUCT_FEED = "PRODUCT_FEED";
export const WEBSITE = "WEBSITE";
export const SOCIAL = "SOCIAL";
export const ANALYTICS = "ANALYTICS";
export const PAYMENT = "PAYMENT";
export const CRM = "CRM";
export const E_COMMERCE = "E-COMMERCE";
export const EMAILING = "EMAILING";
export const REVIEW = "REVIEW";

export const STORE_APPLICATION = "STORE_APPLICATION";
