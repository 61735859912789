import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod } from "../../constant/link-method";
import {NETWORK, BRANCH} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class Branch extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Branch";

  getNetworkIdentifier = (): NETWORK => BRANCH;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = ()  => ADS;

  isETLActivated = () => false;

  alertOptions = () =>  [];

}

export default Branch;
