import React, { useEffect } from "react";
import Private from "../private";
import Public from "../public";
import Loading from "../loading";
import { useMe } from "../../hooks/user";
import JWT from "../../services/auth/jwt";
import config from "../../config";
import decode from "jwt-decode";
import { analytics } from "../../services/analytics";

const Root = () => {
    let jwt = JWT.getToken();

    const current_time = (new Date()).getTime() / 1000;

    if(jwt !== null && (decode(jwt) as any).exp < current_time) {
        JWT.clearToken();
        jwt = null;
    }

    if(!jwt){
        return <Public />
    }


    const { isLoading, isSuccess, data: user } = useMe();

    const isLoggedIn = isSuccess && user !== undefined



    useEffect(() => {
        if(user?.data){
            analytics.identify(user.data.id.toString(), {
                email: user.data.email,
                id: user.data.id,
                'Free trial end at': new Date(user.data.company.trialFinished),
                'Status': user.data.company.stripeSubscriptionId ? 'Payed' : 'Free',
                'lifecyclestage': user.getCompany().stripeSubscriptionId ? 'customer' : 'opportunity',
                company: {
                    name: user.data.company.name,
                    id: user.data.company.id
                }
            })
        }
    }, [user]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return isLoggedIn ? <Private />: <Public />
}

export default Root;
