import React, {useState} from "react";
import {CheckIcon, RefreshIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import {Switch} from "@headlessui/react";
import {useQueryClient} from "react-query";
import qs from "query-string";
import Button from "../../../../ui/components/button/button";
import NetworkAuthorizationInformation
    from "../../../../services/models/http/network-authorization/network-authorization-information";
import { APIKEY, OAUTH_1A, OAUTH_2 } from "../../../../constant/link-method";
import {getAuth2Code, getApiKey} from "../../../../services/network-authentification/popup";
import {NotificationManager} from "../../../../ui/components/notification/notification";
import NetworkProvider from "../../../../config/network/network-provider";
import BaseNetwork from "../../../../config/network/base-network";
import {
    useMutatePostAuthorizeOAuth2, useMutatePutAuthorizeOAuth2,
    useNetworkAuthorizationAutoFetch
} from "../../../../hooks/network-authorization";
import Loader from "../../../../ui/components/loader/loader";
import Authorize from "../../../../services/models/http/network-authorization/authorize";
import {classNames} from "../../../../services/helper";
import {useDeleteMutation, usePostDataSourceMutation} from "../../../../hooks/datasource";
import {DataSourcePostHTTP} from "../../../../services/models/http/datasource/datasource-all";
import NetworkAuthorizationModel from "../../../../services/models/http/network-authorization/network-authorization";
import { analytics } from "../../../../services/analytics";


const GetAllAccounts = ({networkAuthorizationId}: { networkAuthorizationId: number }) => {
    const fetch = useNetworkAuthorizationAutoFetch(networkAuthorizationId);
    const {t} = useTranslation();
    const length = fetch.isError || !fetch.data ? 0 : fetch.data.getAvailableDataSource().length;
    const dataSources = fetch.isError || !fetch.data ? [] : fetch.data.getDataSources();
    const queryClient = useQueryClient();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState<Array<string>>([]);
    const deleteMutation = useDeleteMutation({
        onMutate: async (id: number) => {
            setLoading([...loading, (dataSources.find(elem => elem.id === id) as { accountId: string }).accountId]);
        },

        onSettled: async (returnValue: any, error: any, id: number) => {
            const accountIdTempo = (dataSources.find(elem => elem.id === id) as { accountId: string }).accountId;
            await queryClient.invalidateQueries(['network-authorization', networkAuthorizationId])
            setLoading(loading.filter(elem => elem !== accountIdTempo));
        }
    });

    const postMutation = usePostDataSourceMutation({
        onMutate: async (dataSource: DataSourcePostHTTP) => {
            setLoading([...loading, dataSource.accountId]);
        },

        onSettled: async (dataSource: DataSourcePostHTTP) => {
            await queryClient.invalidateQueries(['network-authorization', networkAuthorizationId])
            setLoading(loading.filter(elem => elem !== dataSource.accountId));
        }
    });

    if (fetch.isLoading) {
        return <Loader active>
            {t("We are currently fetching your accounts...")}
        </Loader>;
    }

    return <>
        <div className="mt-4 text-gray-500"> {t("We have found {{accounts}} accounts.", {accounts: length})} </div>
        <div className="space-y-4 mt-4">
            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                    placeholder="Search"
                />
            </div>
            {fetch.data?.getAvailableDataSource().filter(elem => (search && search.length === 0) || elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1).map(availableDataSource =>
                <Switch.Group as="div"
                              className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          {availableDataSource.name}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {availableDataSource.accountId}
        </Switch.Description>
      </span>
                    <Switch
                        checked={dataSources.find(elem => elem.accountId === availableDataSource.accountId) !== undefined}
                        onChange={(change) => {
                            if (change) {

                                postMutation.mutate({
                                    ...availableDataSource,
                                    authorization: `/api/network_authorizations/${networkAuthorizationId}`
                                });
                                analytics.track("Trying to add a datasource", {source_id: networkAuthorizationId});
                            } else {
                                analytics.track("Trying to delete a datasource", {source_id: networkAuthorizationId});
                                deleteMutation.mutate((dataSources.find(elem => elem.accountId === availableDataSource.accountId) as { id: number }).id)
                            }
                        }}
                        className={classNames(
                            dataSources.find(elem => elem.accountId === availableDataSource.accountId) !== undefined ? "bg-indigo-600" : "bg-gray-200",
                            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                    >
        <span
            className={classNames(
                dataSources.find(elem => elem.accountId === availableDataSource.accountId) !== undefined ? "translate-x-5" : "translate-x-0",
                "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
        >
        <span
            className={classNames(
                dataSources.find(elem => elem.accountId === availableDataSource.accountId) !== undefined || loading.find(elem => elem === availableDataSource.accountId) !== undefined ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
        >
          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
            className={classNames(
                loading.find(elem => elem === availableDataSource.accountId) === undefined ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
        >
          <svg className="animate-spin  h-3 w-3 text-gray-400" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="3" cy="3" r="1" stroke="currentColor" strokeWidth="1"/>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
        </span>

        <span
            className={classNames(
                dataSources.find(elem => elem.accountId === availableDataSource.accountId) !== undefined && loading.find(elem => elem === availableDataSource.accountId) === undefined ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100",
                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
        >
          <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
            <path
                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
          </svg>
        </span>
      </span>
                    </Switch>
                </Switch.Group>)}
        </div>
    </>;

};


const ConnectionHandler = ({
                               name,
                               authorization,
                               onSuccess,
                               initValue
                           }: { name?: string, initValue?: NetworkAuthorizationModel, authorization: NetworkAuthorizationInformation, onSuccess: (success: boolean) => void }) => {
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);
    const [connected, setConnected] = useState(!!initValue);
    const oauth2Mutation = useMutatePostAuthorizeOAuth2();
    const oauth2PutMutation = useMutatePutAuthorizeOAuth2();
    const [networkAuthorization, setNetworkAuthorization] = useState<Authorize | null>(initValue as Authorize);
    const handleAuthorization = async (network: NetworkAuthorizationInformation) => {
        const isHttps = window.location.protocol === 'https:';
        const endpoint = network.getInternalEndpoint() ? `${window.location.protocol}//${window.location.hostname}:${window.location.port || (isHttps ? '443' : '80')}${network.getInternalEndpoint()}` as string : network.getEndpoint() as string;

        let stringParsedQuery : qs.ParsedQuery<string> = {};
        try {
            setSaving(true);
            if (network.getAuthType() === OAUTH_2) {
                analytics.track("Trying to get oauth code", {authorization_type: authorization.getType()});
                const {
                    code,
                    options,
                    query,
                } = await getAuth2Code(network.getInternalEndpoint() ? network.getInternalEndpoint() as string : network.getEndpoint() as string, network.getType()) as { code: string, options: any, query: any };
                stringParsedQuery = query;
                if (initValue) {
                    const data = await oauth2PutMutation.mutateAsync({
                        platform: network.getType(),
                        code,
                        options,
                        id: initValue.getId()
                    });
                    setNetworkAuthorization(data);
                    NotificationManager.success(t("The integrations has been updated."), t("You could now get your data again.", {authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName()}));
                    setNetworkAuthorization(data);
                } else {
                    const data = await oauth2Mutation.mutateAsync({
                        platform: network.getType(),
                        code,
                        options,
                        name: name || network.getType().replace("-", " ").toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase())
                    });
                    setNetworkAuthorization(data);
                    NotificationManager.success(t("The new integration has been added."), t("All your account will be soon available for {{authorization}}.", {authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName()}));
                }
                analytics.track("Successfull Oauth2 connection", {authorization_type: authorization.getType()})
                setConnected(true);
                onSuccess(true);
            } else if (network.getAuthType() === OAUTH_1A) {
                analytics.track("Trying to get oauth code", {authorization_type: authorization.getType()});
                const {
                    code,
                    options,
                    query,
                } = await getAuth2Code(network.getInternalEndpoint() ? network.getInternalEndpoint() as string : network.getEndpoint() as string, network.getType()) as { code: string, options: any, query: any };
                console.log(code,query,options);
                stringParsedQuery = query;
                if (initValue) {
                    const data = await oauth2PutMutation.mutateAsync({
                        platform: network.getType(),
                        oauthToken: query.oauth_token,
                        oauthVerifier: query.oauth_verifier,
                        options,
                        id: initValue.getId()
                    });
                    setNetworkAuthorization(data);
                    NotificationManager.success(t("The integrations has been updated."), t("You could now get your data again.", {authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName()}));
                    setNetworkAuthorization(data);
                } else {
                    const data = await oauth2Mutation.mutateAsync({
                        platform: network.getType(),
                        oauthToken: query.oauth_token,
                        oauthVerifier: query.oauth_verifier,
                        options,
                        name: name || network.getType().replace("-", " ").toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase())
                    });
                    setNetworkAuthorization(data);
                    NotificationManager.success(t("The new integration has been added."), t("All your account will be soon available for {{authorization}}.", {authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName()}));
                }
                analytics.track("Successfull Oauth2 connection", {authorization_type: authorization.getType()})
                setConnected(true);
                onSuccess(true);
            } else if (network.getAuthType() === APIKEY) {
                analytics.track("Trying to get api-key", {authorization_type: authorization.getType()});
                const response = await getApiKey(network.getInternalEndpoint() ? network.getInternalEndpoint() as string : network.getEndpoint() as string, network.getType()) as { consumerKey: string, consumerSecret: string, options: any };
                if (initValue) {
                    const data = await oauth2PutMutation.mutateAsync({
                        platform: network.getType(),
                        key: response.consumerKey,
                        secret: response.consumerSecret,
                        options: response.options,
                        id: initValue.getId()
                    });
                    setNetworkAuthorization(data);
                    NotificationManager.success(t("The integrations has been updated."), t("You could now get your data again.", {authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName()}));
                    setNetworkAuthorization(data);
                } else {
                    const data = await oauth2Mutation.mutateAsync({
                        platform: network.getType(),
                        key: response.consumerKey,
                        secret: response.consumerSecret,
                        options: response.options,
                        name: name || network.getType().replace("-", " ").toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase())
                    });
                    setNetworkAuthorization(data);
                    NotificationManager.success(t("The new integration has been added."), t("All your account will be soon available for {{authorization}}.", {authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName()}));
                }
                analytics.track("Successfull Oauth2 connection", {authorization_type: authorization.getType()})
                setConnected(true);
                onSuccess(true);
            }
        } catch (e) {
            analytics.track("Unable to add oauth2", {authorization_type: authorization.getType(), ...stringParsedQuery})
            console.error("An error occurred when we try to add the authorization", {e, stringParsedQuery});
            NotificationManager.error(t("Unable to add a new integrations"), t("There is an error when we try to add the authorization for {{authorization}}. {{error}} {{error_description}} {{error_uri}}", {
                authorization: (NetworkProvider.getByPlatform(network.getType()) as BaseNetwork).getName(),
                error: stringParsedQuery.error ? stringParsedQuery.error : null,
                error_description: stringParsedQuery.error_description ? stringParsedQuery.error_description : null,
                error_uri: stringParsedQuery.error_uri ? stringParsedQuery.error_uri : null,
            }));
        } finally {
            setSaving(false);
        }
    };
    return (
        <div className="w-full">
            {!connected || !networkAuthorization ? <Button className="flex items-center justify-center w-full"
                                                           onClick={() => handleAuthorization(authorization)}
                                                           loading={saving}>{t("Connect your account")}</Button> : <>
                <div className="flex justify-between items-center">
                    <div className="w-full mr-2">
                        <button
                            type="button"
                            disabled
                            className="w-full flex items-center justify-center text-center w-full inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                            {t("Connected")}
                        </button>
                    </div>
                    <div className="w-full ml-2">
                        <button
                            onClick={() => {
                                analytics.track("Trying to refresh authorization", {authorization_type: authorization.getType()})
                                handleAuthorization(authorization)
                            }}
                            type="button"
                            className="w-full flex items-center justify-center text-center w-full inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                            {t("Refresh your authorization")}
                        </button>
                    </div>
                </div>
                <GetAllAccounts networkAuthorizationId={networkAuthorization.getId()}/>
            </>}
        </div>
    );
};

export default ConnectionHandler;
